<nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" href="https://brandfirm.nl" target="_blank">
            <img src="/assets/image/logo/brandfirm.svg" height="30" alt="Brandfirm" loading="lazy" />
        </a>

        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarColor03"
            aria-controls="navbarColor03"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarColor03">
            <ul class="navbar-nav me-auto"></ul>
            <ul class="navbar-nav">
                <a class="btn btn-outline-warning btn-brandfirm my-2 my-sm-0" href="tel:0202170358">
                    Bel ons
                    <i class="fa-light fa-phone-alt fa-fw"></i>
                </a>
            </ul>
        </div>
    </div>
</nav>

<div
    [class.blur]="reportBlurred"
    *ngFor="let page of report?.page; let f = first; let l = last; let i = index"
    [class.d-none]="reportPageActiveIndex !== i"
>
    <div class="container pt-3" style="max-width: 992px">
        <div class="row mt-3">
            <div class="col-2">
                <img
                    class="img-fluid img-round-edge"
                    [src]="page.userRef.user?.slack?.image"
                    onerror="if (this.src != '/assets/placeholder/user_image.profile.png') this.src = '/assets/placeholder/user_image.profile.png';"
                />
            </div>
            <div class="col-10">
                <div class="card mb-2">
                    <div class="card-body">
                        <strong>{{ page.userRef.user?.slack?.name || 'Brandfirm employee' }}</strong>
                        <br />
                        <small class="employee-comment" [innerHTML]="page.comment | htmlEnhance"> </small>
                    </div>
                </div>

                <div class="btn-group float-end">
                    <div class="btn-group" dropdown>
                        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                            {{ reportPageActive?.label }} (Pagina {{ reportPageActiveIndex + 1 }} van {{ report.page.length }})
                        </button>
                        <ul *dropdownMenu class="dropdown-menu">
                            <li role="menuitem">
                                <a
                                    class="dropdown-item"
                                    [class.active]="i === reportPageActiveIndex"
                                    href="javascript:;"
                                    (click)="setPageIndex(i)"
                                    *ngFor="let page of report.page; let i = index"
                                >
                                    {{ i + 1 }}. {{ page.label }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="btn btn-sm btn-primary" [disabled]="f" (click)="pagePrevious()">
                        <i class="fa-light fa-angle-left fa-fw"></i>
                    </button>
                    <button class="btn btn-sm btn-primary" [disabled]="l && reportNextButtonDisabled" (click)="pageNext()">
                        <i class="fa-light fa-angle-right fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-3 mb-5" *ngIf="reportPageActiveIndex === i" style="max-width: 992px">
        <div class="row">
            <div class="col">
                <iframe width="100%" [src]="page.url | safe: 'resourceUrl'" style="height: 75vh" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
