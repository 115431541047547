import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { RatingModalComponent } from 'app/_components/rating-modal/rating-modal.component'
import { PublicService } from 'app/_services/public.service'
import { BsModalService } from 'ngx-bootstrap/modal'
import { SafePipe } from 'app/_pipes/safe.pipe'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { CommonModule } from '@angular/common'
import { ToastrService } from 'ngx-toastr'
import { HtmlEnhancePipe } from 'app/_pipes/html-enhance.pipe'
import { BACKEND_URL } from 'app/app.config'

@Component({
    selector: 'app-report-view',
    templateUrl: './report-view.component.html',
    styleUrls: ['./report-view.component.scss'],
    standalone: true,
    imports: [CommonModule, CKEditorModule, ReactiveFormsModule, FormsModule, BsDropdownModule, SafePipe, HtmlEnhancePipe],
    providers: [BsDropdownModule],
})
export class ReportViewComponent implements OnInit {
    simplicateId

    reportId
    reportDecoded
    reportPreview

    report
    reportBlurred

    reportPageActiveIndex
    reportNextButtonDisabled = false

    get reportPageActive() {
        if (!this.report) return null
        if (this.reportPageActiveIndex >= this.report.page.length) return null
        return this.report.page[this.reportPageActiveIndex]
    }

    get getImageUrl() {
        if (!this.report) return '/assets/placeholder/user_image.profile.png'
        const user = this.report.manageUserRef.user
        if (user && user.image && user.image.profile) return `${BACKEND_URL}/image/${user.image.profile}/icon_256`
        return '/assets/placeholder/user_image.profile.png'
    }

    @ViewChild('reportPageCommentTextarea') reportPageCommentTextarea: ElementRef

    constructor(
        private publicService: PublicService,
        private bsModalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const { clear, preview, page, edit, report } = params
            const paramMap = this.activatedRoute.snapshot.paramMap
            this.reportId = paramMap.get('reportId')

            // eslint-disable-next-line no-extra-boolean-cast
            if (edit) return this.router.navigate([`/main/report/${this.reportId}/page/${+page}/edit`], { replaceUrl: true })

            const stateReport = window.localStorage.getItem('state.report')
            const simplicateIdDefault = 'no-simplicate-id'
            this.reportPageActiveIndex = +page || 0

            const reportStringyFied = stateReport ?? report

            this.simplicateId = paramMap.get('simplicateId') || simplicateIdDefault
            this.reportPreview = !!preview
            this.reportDecoded = this.reportPreview && this.reportDecode(reportStringyFied)

            // Get from server or from query
            if (this.reportDecoded) this.reportShow(this.reportDecoded, clear)
            else this.publicService.getReport(this.reportId).subscribe((res) => this.reportShow(res.data, clear))

            // If not preview mode, log that the user has visited this page
            const reportViewIsCustomer = !this.reportPreview && !this.reportDecoded
            if (reportViewIsCustomer) this.publicService.reportOpened(this.reportId, this.simplicateId).subscribe()
        })
    }

    ratingNpsBlocked
    ratingNpsGiven
    get shouldGiveRating() {
        return !(this.reportPreview || this.ratingNpsGiven || this.ratingNpsBlocked)
    }

    reportShow(report, clear) {
        this.report = report

        const userRefs = this.report.userRef
        const userRef = userRefs.find((uRef) => uRef.userSimplicateId === this.simplicateId) || {}
        if (!userRef) return this.toastrService.error('Geen contactpersoon gevonden voor deze pagina')
        userRef.ratingNPS = clear ? null : userRef.ratingNPS
        userRef.ratingResult = clear ? null : userRef.ratingResult

        this.ratingNpsBlocked = !userRef.ratingNPSActive
        this.ratingNpsGiven = !!userRef.ratingNPS
        this.goToPage(this.reportPageActiveIndex)
        if (!this.shouldGiveRating) return

        const title = 'Hoe groot is de kans dat je Brandfirm zou aanraden bij vrienden of collega’s?'
        const field = 'ratingNPS'
        this.ratingModelShow(title, field)
    }

    reportDecode(enc) {
        try {
            if (!enc) return null
            return JSON.parse(enc)
        } catch (ex) {
            console.warn(ex)
            return null
        }
    }

    setPageIndex(index: number) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                page: index,
            },
            queryParamsHandling: 'merge',
        })
    }

    goToPage(pageIndex) {
        this.setPageIndex(pageIndex)
        let pageLength = this.report.page.length
        if (this.shouldGiveRating) pageLength++
        if (pageIndex >= pageLength - 1) this.reportNextButtonDisabled = true
    }

    async pageNext() {
        const pageLength = this.report.page.length
        const pageActiveNext = this.reportPageActiveIndex + 1
        const pageIsNotLast = pageActiveNext < pageLength
        if (pageIsNotLast) return this.goToPage(pageActiveNext)

        this.reportNextButtonDisabled = true
        if (!this.shouldGiveRating) return

        const title = 'Hoe tevreden ben je met dit resultaat?'
        const field = 'ratingResult'
        this.ratingModelShow(title, field)
    }

    async pagePrevious() {
        this.setPageIndex(this.reportPageActiveIndex - 1)
    }

    async setPageActive(i) {
        this.reportPageActiveIndex = i
    }

    async ratingModelShow(title, field, reportPageIndex = null) {
        const opts = {
            initialState: {
                title,
                field,
                report: this.report,
                reportPageIndex,
                preview: this.reportPreview,
                simplicateId: this.simplicateId,
            },
            class: 'modal-lg modal-dialog-centered',
            keyboard: false,
            ignoreBackdropClick: true,
        }
        this.reportBlurred = true
        this.bsModalService.show(RatingModalComponent, opts)
        this.bsModalService.onHide.subscribe(() => {
            this.reportBlurred = false
        })
    }
}
